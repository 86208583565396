import React from "react"
import "typeface-open-sans"
import "./blog-post.css"

export default function PostDDO() {
  return (
    <div className="blog-post">
      <a className="home-link" href="/">Home</a>
      <h1>Domain-Driven design: Monitoring Strawberries</h1>
      <span class="blog-post-date">October 2020</span>

      <p>From 2017 to 2020, I worked as software developer for <a href="https://www.agricool.com">Agricool</a>, an indoor farming company that
        builds IoT technologies to grow fruits and vegetables inside industrial containers.
        Below is <a href="http://bofh.nikhef.nl/events/FOSDEM/2020/UD2.120/strawberries.mp4">a talk I gave at FOSDEM
          2020</a> about how Agricool builds observability pipelines to make IoT farming systems more reliable.
      </p>

      <video preload="none" controls="controls">
        <source src="https://video.fosdem.org/2020/UD2.120/strawberries.webm"
                type="video/webm; codecs=&quot;vp9, opus&quot;" />
      </video>

      {/*Many tech profiles*/}
      <p>
        One of the most interesting aspects of building observability for farming environments is that it requires a
        collaborative effort from many different technical experts, each one having a role to play
        to ensure the reliability of the overall systems. In the case of Agricool, observability tools are designed and used
        by agronomists (both scientists and operators), chemists, industrial engineers, electical engineers,
        IoT engineers and software developers.
      </p>

      {/*DDD helpful*/}
      <p>
        From this experience, I learned that building observability pipelines can sometimes
        benefit from ideas borrowed from
        the <a href="https://en.wikipedia.org/wiki/Domain-driven_design"> Driven Design community</a>.
        Which led me to describe our work as a case of <strong>Domain-Driven Observability</strong> - or DDO.
      </p>

      {/*DDO = collaboration*/}
      <p>
        DDD and DDO intersect in terms of their collaborative nature.
        The same way DDD encourages domain experts to participate in the design of domain models,
        DDO encourages the involvement of domain experts in both using  and designing observability tools.
      </p>

      {/*=> three lessons*/}

      {/*<p>*/}
      {/*  What drives the adoption of DDD in software projects is a need for close collaboration between developers and*/}
      {/*  domain experts. Similarly, the term DDO highlights the need for a friction-less collaboration between*/}
      {/*  technical contributors who can deploy observability tools, and the domain experts who will use the tools to*/}
      {/*  ensure the reliability of the target system.</p>*/}

      <h2>Invest in an ubiquitous language</h2>
      <p>
        One DDD concept that is particularly relevant for DDO is the search for an <em>ubiquitous language</em>.
        The ubiquitous language is meant to be used to name graphs, metrics, metric
        units, alerts, domain events, etc.

        And most importantly, the ubiquitous language is meant to be used in all team
        discussions, so that all stakeholders are aligned in terms of what the observability assets do, what their
        representations mean in the context of the application domain, and how they ought
        to evolve in the future.
      </p>

      <h2>Reduce cognitive distance between talk and code</h2>

      <p>
        When developers and devops engineers enforce the use of the ubiquitous language
        within their infrastructure-as-code assets, they are bound to accelerate the
        evolution of these assets over time.

        Instead of translating from a mental model used with domain experts to another mental model
        used within the code base, we make a conscious effort to align both worlds behind a common language
        and reach greater speed when implementing changes.
      </p>

      {/*Collective learning enables clarity of thinking and accountability*/}
      <h2>Collective learning</h2>
      <p>
        This collective alignment then feeds an accelerating learning curve that helps everybody.
        On one hand, domain experts learn about the role of observability to improve the reliability of systems.
        On the other hand, devops people acquire the domain knowledge they need to build the most helpful observability
        tools.
      </p>

      <p>
        The reliability of an advanced indoor farming systems depends on many properties from many different abstraction
        layers. Each abstraction layer belongs to a rich discipline: plant physiology, chemistry, indoor climate
        regulation, electrical engineering, IoT, and software infrastructure. I believe that the only way to make
        steady observability improvements in such a rich context, is for everyone to learn from anyone else about their
        respective discipline.
      </p>

      <p>
        Collective learning is fun and generates two very important things.
        First, a <strong>shared clarity of thinking</strong> about system reliability.
        Second, every team who contributes to the system is made <strong>accountable</strong> for its observability.
      </p>
    </div>
  )
}
